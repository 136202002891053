.goal-list {
    .inner {
        margin-left: 1.4rem;
    }
}

.goal-list-item {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}