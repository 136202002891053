.goal-list-item {
    cursor: pointer;
    padding: .3rem;
    border-radius: .3rem;
}

.goal-list-item:hover {
    background-color: #eee;
}

.goal-list-item-icon {
    padding-top: .3rem !important;
}

.goal-tree {

    &.inner-goal {
        margin-left: 2rem;
    }

    .goal-item {
        cursor: pointer;
        display: block;
        border-radius: .3rem;

        &:hover {
            background-color: #eee;

            .goal-icon {
                color: green;
            }
        }

        .goal-icon {
            margin-top: .4rem;
            margin-left: .4rem;
            vertical-align: top;
            display: inline-block;
        }

        .goal-details {
            display: inline-block;
            padding: .3rem;
            width: calc(100% - 2rem);

            .goal-title {
                display: block;
                font-weight: 700;
            }

            .goal-description {
                display: block;
                opacity: .6;
            }
        }
    }

    .goal-separator {
        width: 100%;
        border-radius: .3rem;
        padding-top: .3rem;
        padding-bottom: .3rem;
        cursor: pointer;

        &:hover {
            .goal-separator-divider {
                background-color: #eee;
            }
        }

        .goal-separator-divider {
            height: .4rem;
        }

        .new-goal-inline-form {
            // height: 10rem;
            padding-left: 2rem;
        }
    }
}