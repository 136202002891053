.editor {
    padding: 2rem;
    border: 1px solid rgba(#ccc, .4);
    font-size: 1.5rem;
    line-height: 1.8rem;
    border-radius: .3rem;

    &:focus{
        outline: none;
    }
}

.tooltip {
    position: absolute;
    width: 10rem;
    height: 10rem;
    background-color: #ccc;
    border-radius: .3rem;
    opacity: 0;

    &.active {
        opacity: 1;
    }

    .tooltip-item {
        padding: .5rem;
        
        &.active {
            background-color: #000;
            color: #fff;
        }
    }
}