.bookmark {
    margin-bottom: 2rem;

    .bookmark-title {
        margin-bottom: .6rem;
    }

    .bookmark-topic {
        display: inline-block;
        margin: 0 .5rem .5rem 0;
        border: .1rem solid #ccc;
        padding: .3rem .6rem;
        border-radius: .3rem;
        cursor: pointer;

        &:hover {
            background-color: #ccc;
        }
    }
}

.tiktok-embed {
    section {
        max-width: calc(32.5rem / 2) !important;
        min-width: calc(32.5rem / 2) !important;
        min-height: calc(58rem / 2) !important;
        max-height: calc(58rem / 2) !important;

        &::before {
            max-width: calc(32.5rem / 2) !important;
            min-width: calc(32.5rem / 2) !important;
            min-height: calc(58rem / 2) !important;
            max-height: calc(58rem / 2) !important;
        }
    }
}

$iframe-scale: 0.5;

iframe {
    -moz-transform: scale($iframe-scale, $iframe-scale); 
    -webkit-transform: scale($iframe-scale, $iframe-scale); 
    -o-transform: scale($iframe-scale, $iframe-scale);
    -ms-transform: scale($iframe-scale, $iframe-scale);
    transform: scale($iframe-scale, $iframe-scale); 
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -o-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
  }